import { Component } from 'react'
import cn from 'classnames'
import { Layout, Flex, Box } from 'react-flex-lite'
import { isHub } from 'lib/hub'
import context from 'meta/context'
import LinkButton from 'components/interactive/LinkButton'
import TextInput from 'components/interactive/TextInput'
import PropTypes from 'meta/PropTypes'
import api from 'connections/api'
import { MdSearch, MdClose } from 'components/display/Icons'
import HighLightText from 'components/interactive/HighLightText'
import { Medium } from 'components/display/Text'
import { naturalSortBy } from 'lib/naturalSortBy'

import './index.scss'

@context([ 'place' ])
@Layout
export default class HubSearch extends Component {
  static propTypes = {
    place: PropTypes.place.isRequired,
    className: PropTypes.string,
    fullWidth: PropTypes.bool
  }
  state = {
    search: '',
    searchActive: false,
    loading: false,
    domains: [],
    indicators: [],
    dataStories: []
  }

  resolveSearch = async (search) => {
    const placeId = this.props.place.id
    try {
      this.setState({
        loading: true
      })
      const { body: domains } = await api.place.dataType.find({
        retry: 3,
        placeId,
        options: {
          search,
          maxVisibility: 'public',
          includes: [
            {
              resource: 'sources',
              attributes: [
                'id'
              ]
            }
          ]
        }
      })
      const { body: indicators } = await api.place.source.find({
        retry: 3,
        placeId,
        options: {
          search,
          maxVisibility: 'public',
          includes: [
            {
              resource: 'dataType',
              attributes: [
                'id', 'name', 'category'
              ]
            }
          ]
        }
      })
      const { body: dataStories } = await api.place.page.find({
        retry: 3,
        placeId,
        options: {
          search,
          visibility: 'public',
          publish: 'published'
        }
      })

      const sortedDomains = naturalSortBy(domains.results, [ 'name' ])
      const sortedIndicators = naturalSortBy(indicators.results, [ 'name' ])
      const sortedDataStories = naturalSortBy(dataStories.results, [ 'title' ])
      this.setState({
        domains: sortedDomains,
        indicators: sortedIndicators,
        dataStories: sortedDataStories,
        loading: false
      })
    } catch (error) {
      this.setState({
        domains: [],
        indicators: [],
        dataStories: [],
        loading: false
      })
      console.error(error)
    }
  }

  handleSearch = async (e) => {
    const search = e.target.value
    this.setState({
      search,
      searchActive: false,
      domains: [],
      indicators: [],
      dataStories: []
    })
    if (!search) {
      this.setState({
        searchActive: false,
        domains: [],
        indicators: [],
        dataStories: []
      })
    }
  }

  handleSearchClick = async () => {
    if (this.state.search) {
      this.setState({ searchActive: true })
      this.resolveSearch(this.state.search)
    }
  }

  clearSearch = () => {
    this.setState({
      search: '',
      searchActive: false,
      domains: [],
      indicators: [],
      dataStories: []
    })
  }


  render = () => {
    const {
      search, searchActive, loading,
      domains, indicators, dataStories } = this.state
    if (!isHub()) return null

    return <Box className={cn(`hub-search-component ${this.props.className} ${this.props.fullWidth ? 'full-width' : ''}`)} >
      <TextInput
        value={search}
        className="search-box"
        placeholder="Search domains, indicators, maps, stories, etc."
        onChange={this.handleSearch}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            this.handleSearchClick()
          }
        }}
        postfix={
          <Box className="action-btn-container">
            {search && <LinkButton className="close" plain mr={1} Icon={MdClose} onClick={this.clearSearch} />}
            {!search && <LinkButton className="search" plain Icon={MdSearch} />}
            {search && <LinkButton className="search with-text" plain Icon={MdSearch} onClick={this.handleSearchClick}>Search</LinkButton>}
          </Box>
        } />

      {search && searchActive && <Flex column className="search-results">
        {loading && <Box className="search-result loader">Searching...</Box>}
        {!loading ?
          domains.length === 0 && indicators.length === 0 && dataStories.length === 0
            ? <Box className="search-result empty">No results!</Box>
            : <>
              {<p className="search-result search-count">{`${domains.length + indicators.length + dataStories.length} search results`}</p>}
              {domains.length > 0 && <Medium className="search-result-group-heading">Domains</Medium>}
              {domains.length > 0 && domains.map((i) =>
                <a
                  className="search-result"
                  href={i.sources.length > 0 ? `/domains/${i.id}/source/${i.sources[0]?.id}` : '/domains'}
                  key={i.id}>
                  <p className="sub-domain-name">{`${i.category}`}</p>
                  <HighLightText className="source-name result-name" value={i.name} searchText={search} />
                </a>
              )}
              {indicators.length > 0 && <Medium className="search-result-group-heading">Indicators</Medium>}
              {indicators.length > 0 && indicators.map((i) =>
                <a
                  className="search-result"
                  href={`/domains/${i.dataTypeId}/source/${i.id}`}
                  key={i.id}>
                  <p className="sub-domain-name">{`${i.dataType.category} - ${i.dataType.name}`}</p>
                  <HighLightText className="source-name result-name" value={i.name} searchText={search} />
                </a>
              )}
              {dataStories.length > 0 && <Medium className="search-result-group-heading">Data Stories</Medium>}
              {dataStories.length > 0 && dataStories.map((i) =>
                <a
                  className="search-result"
                  href={`/pages/${i.type}/${i.id}/preview`}
                  key={i.id}>
                  <HighLightText className="source-name result-name" value={i.title} searchText={search} />
                </a>
              )}
            </>
          : null
        }
      </Flex>}
    </Box>
  }
}
