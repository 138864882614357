import { Component } from 'react'
import context from 'meta/context'
import cn from 'classnames'
import PropTypes from 'meta/PropTypes'
import { Layout, Flex, Box } from 'react-flex-lite'
import { isHub } from 'lib/hub'
import { Medium } from 'components/display/Text'
import LinkButton from 'components/interactive/LinkButton'
import { MdSearch, MdOutlineTranslate, WiMoonAltWaningCrescent6, MdOutlineLightMode } from 'components/display/Icons'
import { ThemeContext } from 'components/display/ThemeProvider'
import api from 'connections/api'
import numeral from 'numeral'
import HubSearch from 'components/navigation/HubSearch'
import Modal from 'components/interactive/Modal'

import './index.scss'

@context([ 'place' ])
@Layout
export default class HubHeader extends Component {
  static propTypes = {
    place: PropTypes.place.isRequired
  }

  static defaultProps = {}

  state = {
    indicatorsCount: null,
    openSearchModel: false
  }

  async componentDidMount() {
    await api.place.source.find({
      placeId: this.props.place.id,
      options: {
        maxVisibility: 'public'
      }
    }).then((res) => {
      this.setState({
        indicatorsCount: numeral(res.body.meta.total).format('0,0')
      })
    })
  }

  static contextType = ThemeContext

  toggleSearchModel = () => {
    this.setState((prev) => ({
      openSearchModel: !prev.openSearchModel
    }))
  }

  renderSearchModal = () => {
    const { openSearchModel } = this.state
    return (
      <Modal
        className="search-model-container"
        onRequestClose={this.toggleSearchModel}
        isOpen={!!openSearchModel}>
        <HubSearch />
      </Modal>
    )
  }


  render = () => {
    const { theme, toggleTheme } = this.context
    const { indicatorsCount } = this.state

    if (!isHub()) return null
    return <>
      <Flex
        className={cn('hub-top-header-component', { dark: theme === 'dark', light: theme === 'light' })}
        justify="space-between"
        align="center">
        <Flex className="left-container" align="center">
          <Box className="indicators-count">
            <Medium className="label">INDICATORS</Medium>
            <Medium className="value">{indicatorsCount}</Medium>
          </Box>
        </Flex>
        <Flex className="right-container" align="center">
          <LinkButton className="search" plain Icon={MdSearch} onClick={this.toggleSearchModel}>
            Search
          </LinkButton>
          <LinkButton className="translate" plain Icon={MdOutlineTranslate}>
            Translate
          </LinkButton>
          {theme === 'dark-theme' ?
            <MdOutlineLightMode
              className="theme-icon"
              onClick={toggleTheme} />
            :
            <WiMoonAltWaningCrescent6
              className="theme-icon"
              onClick={toggleTheme} />
          }
        </Flex>
      </Flex>
      {this.renderSearchModal()}
    </>
  }
}
